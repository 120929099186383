import {
  ArrowDownTrayIcon,
  BoltIcon,
  CheckCircleIcon,
  XCircleIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import { useCallback, useContext, useEffect, useState } from "react";
import Pagination from "../../components/Pagination/Pagination";
import NoContent from "../../components/TableNoContentPage/NoContent";
import { TransactionType, transactionMap } from "../../interfaces";
import {
  amountFormater,
  classNames,
  getTimestamp,
  parseDate,
} from "../../utils";
import moment from "moment";
import { transactionCollectionName } from "../../config";
import { UserContext } from "../../contexts";
import { db } from "../../firebase";
import * as XLSX from "xlsx";
import SankMoneyIcon from "../../CFA.png";
import toast from "react-hot-toast";

const tableHeaderCollect = [
  "N°",
  "Nom destinataire",
  "Numéro destinataire",
  "Montant",
  "Type de transaction",
  "Compte",
  "Frais",
  "Point de vente",
  "Identifiant point de vente",
  "Status",
  "Date de création",
];

const transactionTypes = [TransactionType.FUNDRAISE, TransactionType.RELOAD];

const CollectorsAllTransactions = () => {
  const [transactions, setTransactions] = useState<any[]>([]);
  const { user, collectorAffiliatedCollectionPoints } = useContext(UserContext);
  const [pointOfCollectId, setPointOfCollecteId] = useState(
    collectorAffiliatedCollectionPoints
      ? collectorAffiliatedCollectionPoints[0].id
      : ""
  );

  const [defaultLoadHistories, setDefaultLoadHistories] = useState<number>(10);
  const [transactionTotalSum, setTransactionTotalSum] = useState<number>(0);
  const [transDataLimitLength, setTransDataLimitLength] = useState<number>(100);

  const [selectedTransactionsTypeVal, setSelectedTransactionsTypeVal] =
    useState<TransactionType>(TransactionType.FUNDRAISE);
  const [searchWithStartDateValue, setSetsearchWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [searchWithEndDateValue, setSetsearchWithEndDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);

  const handleChangeStartDateValue = (value: any) => {
    setSetsearchWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    setSetsearchWithEndDateValue(value.target.value);
  };

  const retrieveFilteredTransactions = useCallback(async () => {
    const startTime = getTimestamp(searchWithStartDateValue, true);
    const endTime = getTimestamp(searchWithEndDateValue, false);
    if (startTime > endTime) {
      return toast.error(
        "La date de fin ne doit pas etre inférieur à la date de début."
      );
    }

    const transSnap = await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("participantsIds", "array-contains", pointOfCollectId)
      .where("transactionType", "==", selectedTransactionsTypeVal.toString())
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .limit(transDataLimitLength)
      .get();
    if (transSnap.empty) {
      setDefaultLoadHistories(0);
      setTransactionTotalSum(0);
      setTransactions([]);
    } else {
      const trans = transSnap.docs.map((doc) => {
        return { id: doc.id, data: doc.data() };
      });
      let sum = 0;
      transSnap.forEach((doc) => (sum = sum + parseFloat(doc.data()?.amount)));
      setDefaultLoadHistories(transSnap.size);
      setTransactionTotalSum(sum);
      setTransactions(trans);
    }
  }, [
    pointOfCollectId,
    searchWithEndDateValue,
    searchWithStartDateValue,
    selectedTransactionsTypeVal,
    transDataLimitLength,
  ]);

  const filterWithPhoneNumber = useCallback(
    (value: string) => {
      const filteredTrans = transactions.filter((doc) => {
        return (doc.data.pointOfSaleIdentityCode as string).includes(value);
      });
      const trans = filteredTrans.slice(0, transDataLimitLength);
      let sum = 0;
      trans.forEach((doc) => (sum = sum + parseFloat(doc.data?.amount)));
      setTransactionTotalSum(sum);
      setTransactions(trans);
    },
    [transDataLimitLength, transactions]
  );

  const exportToXLSData = useCallback(() => {
    const worksheet = XLSX.utils.json_to_sheet(
      transactions.map((transaction) => {
        return {
          Date: parseDate(transaction.data?.createdAt as Timestamp),
          id: transaction?.id,
          "Nom du client": transaction.data.senderDisplayName,
          "Numero du client": transaction.data.senderPhoneNumber,
          "Nom de la station": transaction.data.receiverDisplayName,
          "Code de la station": transaction.data.receiverPhoneNumber,
          Montant: transaction.data.amount,
          "Type de transaction": transaction.data.transactionType,
          Frais: transaction.data.fees,
          "Type de compte": transaction.data.accountType,
          Statut: transaction.data.status ?? "---",
          "Identifiant du point de vente":
            transaction.data.pointOfSaleIdentityCode ?? "---",
          "Nom du point de vente":
            transaction.data.pointOfSaleIdentityName ?? "---",
          "Telephone du point de vente":
            transaction.data.pointOfSaleIdentityPhoneNumber ?? "---",
        };
      })
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "export");

    XLSX.writeFile(
      workbook,
      `transactions_du_${searchWithStartDateValue}_au_${searchWithEndDateValue}.xlsx`,
      { compression: true }
    );
  }, [searchWithEndDateValue, searchWithStartDateValue, transactions]);

  useEffect(() => {
    retrieveFilteredTransactions();
  }, [retrieveFilteredTransactions]);

  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="flex">
            <div>
              <h1 className="text-xl font-semibold text-gray-900">
                Mes historiques de transactions{" "}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Vous trouverez ici l'ensemble de vos historiques de
                transactions.
              </p>
            </div>
            <span className="hidden text-xs font-medium text-red-500 md:inline-block">
              ({`${amountFormater(transactionTotalSum)} Fcfa`})
            </span>
          </div>

          <div className="h-8" />
          <div className="flex flex-col items-end justify-end py-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/2">
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  rechercher par telephone
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    onChange={(e) => filterWithPhoneNumber(e.target.value)}
                    type="text"
                    id="simple-search"
                    className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                    placeholder="Rechercher par identifiant (POS)"
                  />
                </div>
              </form>
            </div>
            <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
              <button
                type="button"
                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-700"
                onClick={() => retrieveFilteredTransactions()}
              >
                <ArrowPathIcon
                  className="self-center flex-shrink-0 w-5 h-5 mr-1"
                  aria-hidden="true"
                />
                Refresh
              </button>
              <select
                id="countries"
                className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 truncate bg-white border border-gray-200 rounded-lg md:w-36 focus:outline-none hover:bg-gray-100 hover:text-red-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onChange={(e) => {
                  setPointOfCollecteId(e.target.value);
                }}
              >
                {collectorAffiliatedCollectionPoints &&
                  collectorAffiliatedCollectionPoints.map((opt, index) => (
                    <option key={index} value={opt.id}>
                      {opt.displayName}
                    </option>
                  ))}
              </select>
              <select
                id="countries"
                className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 truncate bg-white border border-gray-200 rounded-lg md:w-36 focus:outline-none hover:bg-gray-100 hover:text-red-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onChange={(e) =>
                  setSelectedTransactionsTypeVal(e.target.value as any)
                }
              >
                {transactionTypes.map((value, index) => (
                  <option key={index} value={value}>
                    {transactionMap.get(value)}
                  </option>
                ))}
              </select>
              <div className="flex justify-between md:space-x-3 md:w-auto">
                <input
                  type="date"
                  id="dateDebut"
                  className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeStartDateValue}
                />
                <input
                  type="date"
                  id="dateDebut"
                  className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeEndDtaeValue}
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className={classNames(
                    transactions && transactions.length > 0
                      ? "text-green-700 border-green-300 hover:bg-green-50 focus:ring-green-500"
                      : "text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-gray-500 cursor-not-allowed",
                    "inline-flex items-center h-10 px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                  )}
                  onClick={() => exportToXLSData()}
                  disabled={
                    transactions && transactions.length > 0 ? false : true
                  }
                >
                  <ArrowDownTrayIcon
                    className={classNames(
                      transactions && transactions.length > 0
                        ? "text-green-500"
                        : "text-gray-500",
                      "self-center flex-shrink-0 w-5 h-5"
                    )}
                    aria-hidden="true"
                  />
                  <span className="sr-only">Exporter en XLS</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 overflow-x-auto">
          <table
            className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
            style={{ borderSpacing: 0 }}
          >
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                {tableHeaderCollect.map((th, index) => (
                  <th key={index} scope="col" className="px-4 py-3">
                    {th}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="items-center">
              {transactions && transactions.length > 0 ? (
                transactions.map((transaction: any, transactionIdx: number) => (
                  <tr
                    key={transactionIdx}
                    className={
                      transactionIdx % 2 === 0 ? "bg-white" : "bg-gray-100"
                    }
                  >
                    <th
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {(transactionIdx as number) + 1}
                    </th>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {user && user?.id === transaction.data.senderID
                        ? transaction.data.receiverDisplayName
                          ? transaction.data.receiverDisplayName
                          : "-"
                        : transaction.data.senderDisplayName
                        ? transaction.data.senderDisplayName
                        : "-"}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {user && user?.id === transaction.data.senderID
                        ? transaction.data.receiverPhoneNumber
                        : transaction.data.senderPhoneNumber}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {amountFormater(parseFloat(transaction.data.amount))} Fcfa
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {transactionMap.get(transaction.data.transactionType)}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {transaction.data.accountType === "MAIN" ? (
                        <img
                          className="w-auto h-5"
                          src={SankMoneyIcon}
                          alt="Sank"
                        />
                      ) : (
                        <BoltIcon className="w-6 h-6 text-red-500" />
                      )}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {transaction.data.fees as string} Fcfa
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {transaction.data.pointOfSaleIdentityName ?? "-"}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {transaction.data.pointOfSaleIdentityCode ?? "-"}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {transaction.data.isComplete ? (
                        <CheckCircleIcon className="w-6 h-6 text-green-500" />
                      ) : (
                        <XCircleIcon className="w-6 h-6 text-red-500" />
                      )}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {(transaction.data.createdAt as Timestamp)
                        .toDate()
                        .toLocaleString()}
                    </td>
                  </tr>
                ))
              ) : (
                <NoContent />
              )}
            </tbody>
          </table>

          <Pagination
            skip={defaultLoadHistories}
            take={defaultLoadHistories}
            total={defaultLoadHistories}
            collectionName={transactionCollectionName}
            isChangedPage={true}
            onPageChange={(pageIndex: number) => pageIndex}
            onPageLimitChange={(pageLimit: number) =>
              setTransDataLimitLength(pageLimit)
            }
            balance={transactionTotalSum ? transactionTotalSum : undefined}
          />
        </div>
      </div>
    </>
  );
};

export default CollectorsAllTransactions;
