import {
  BoltIcon,
  CheckCircleIcon,
  XCircleIcon,
  ArrowPathIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import Pagination from "../../../components/Pagination/Pagination";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import {
  transactionCollectionName,
  gasStationMembershipCollectionName,
} from "../../../config";
import { UserContext } from "../../../contexts";
import { db } from "../../../firebase";
import { TransactionType, transactionMap } from "../../../interfaces";
import {
  getTimestamp,
  classNames,
  parseDate,
  amountFormater,
} from "../../../utils";
import * as XLSX from "xlsx";
import SankMoneyIcon from "../../../CFA.png";
import toast from "react-hot-toast";

const tableHeader = [
  "N°",
  "",
  "Nom destinataire",
  "Numéro destinataire",
  "Montant",
  "Type de transaction",
  "Compte",
  "Frais",
  "Status",
  "Date de création",
  // "Actions",
];

const transactionTypes = [TransactionType.PAYMENT, TransactionType.TRANSFER];

const GasStationTransactionsHistories = () => {
  const { user } = useContext(UserContext);

  const [transactions, setTransactions] = useState<any[]>([]);

  const [defaultLoadHistories, setDefaultLoadHistories] = useState<number>(10);
  const [transactionTotalSum, setTransactionTotalSum] = useState<number>(0);
  const [transDataLimitLength, setTransDataLimitLength] = useState<number>(100);

  const [gasStationRelationId, setGasStationRelationId] = useState("");
  const [selectedTransactionsTypeVal, setSelectedTransactionsTypeVal] =
    useState<TransactionType>(TransactionType.PAYMENT);
  const [searchWithStartDateValue, setSetsearchWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [searchWithEndDateValue, setSetsearchWithEndDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);

  const handleChangeStartDateValue = (value: any) => {
    setSetsearchWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    setSetsearchWithEndDateValue(value.target.value);
  };

  const retriveGasStationId = useCallback(async () => {
    if (user?.attendantRole) {
      const membershipRef = db.collection(gasStationMembershipCollectionName);
      const membershipSnaphot = await membershipRef
        .where("memberId", "==", user?.id)
        .get();
      const membershipData = membershipSnaphot.docs[0]?.data();
      if (membershipData) {
        setGasStationRelationId(membershipData?.gasStationId);
        return membershipData?.gasStationId as string;
      }
    }
  }, [user?.attendantRole, user?.id]);

  const retrieveFilteredTransactions = useCallback(async () => {
    const startTime = getTimestamp(searchWithStartDateValue, true);
    const endTime = getTimestamp(searchWithEndDateValue, false);
    if (startTime > endTime) {
      return toast.error(
        "La date de fin ne doit pas etre inférieur à la date de début."
      );
    }
    const transSnap = await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("participantsIds", "array-contains", gasStationRelationId)
      .where(
        "transactionType",
        "==",
        selectedTransactionsTypeVal
          ? selectedTransactionsTypeVal.toString()
          : TransactionType.PAYMENT
      )
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .limit(transDataLimitLength)
      .get();
    if (transSnap.empty) {
      setTransactions([]);
    } else {
      const trans = transSnap.docs.map((doc) => {
        return { id: doc.id, data: doc.data() };
      });
      let sum = 0;
      transSnap.forEach((doc) => (sum = sum + parseFloat(doc.data()?.amount)));
      setDefaultLoadHistories(transSnap.size);
      setTransactionTotalSum(sum);
      setTransactions(trans);
    }
  }, [
    gasStationRelationId,
    searchWithEndDateValue,
    searchWithStartDateValue,
    selectedTransactionsTypeVal,
    transDataLimitLength,
  ]);

  // const filterWithPhoneNumber = useCallback(
  //   (value: string) => {
  //     const filteredTrans = transactions.filter((doc) => {
  //       if (user?.id === doc.data.senderID) {
  //         return (doc.data.receiverPhoneNumber as string).includes(value);
  //       }
  //       return (doc.data.senderPhoneNumber as string).includes(value);
  //     });
  //     setTransactions(filteredTrans.slice(0, transDataLimitLength));
  //   },
  //   [transDataLimitLength, transactions, user?.id]
  // );

  // const onDetails = (row: any, rowId: string) => {
  //   if (row) {
  //     navigate(`/transactions/${rowId}/detail`, {
  //       state: { entity: row },
  //     });
  //   }
  // };

  const exportToXLSData = useCallback(() => {
    const worksheet = XLSX.utils.json_to_sheet(
      transactions.map((transaction) => {
        return {
          Expéditeur: transaction.data.senderPhoneNumber,
          Destinataire: transaction.data.receiverPhoneNumber,
          Montant: transaction.data.amount,
          "Type de Transaction": transaction.data.transactionType,
          Frais: transaction.data.fees,
          "Type de compte": transaction.data.accountType,
          Statut: transaction.data.status ?? "---",
          Date: parseDate(transaction.data?.createdAt as Timestamp),
        };
      })
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "export");

    XLSX.writeFile(
      workbook,
      `transactions_du_${searchWithStartDateValue}_au_${searchWithEndDateValue}.xlsx`,
      { compression: true }
    );
  }, [searchWithEndDateValue, searchWithStartDateValue, transactions]);

  useEffect(() => {
    retriveGasStationId();
    retrieveFilteredTransactions();
  }, [retrieveFilteredTransactions, retriveGasStationId]);

  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="flex">
            <div>
              <h1 className="text-xl font-semibold text-gray-900">
                Mes historiques de transactions{" "}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Vous trouverez ici l'ensemble de vos historiques de
                transactions.
              </p>
            </div>
            <span className="hidden text-xs font-medium text-red-500 md:inline-block">
              ({`${amountFormater(transactionTotalSum)} Fcfa`})
            </span>
          </div>

          <div className="h-8" />
          <div className="flex flex-col items-end justify-end p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            {/* <div className="w-full md:w-1/2">
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  rechercher par telephone
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    onChange={(e) => filterWithPhoneNumber(e.target.value)}
                    type="text"
                    id="simple-search"
                    className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                    placeholder="Rechercher par téléphone"
                  />
                </div>
              </form>
            </div> */}
            <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
              {/* <button
                type="button"
                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-700"
                onClick={() => retrieveFilteredTransactions()}
              >
                <ArrowPathIcon
                  className="self-center flex-shrink-0 w-5 h-5 mr-1"
                  aria-hidden="true"
                />
                Refresh
              </button> */}
              <select
                id="countries"
                className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-36 focus:outline-none hover:bg-gray-100 hover:text-red-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onChange={(e) =>
                  setSelectedTransactionsTypeVal(e.target.value as any)
                }
              >
                <option value={""}>choisir...</option>
                {transactionTypes.map((value, index) => (
                  <option key={index} value={value}>
                    {transactionMap.get(value)}
                  </option>
                ))}
              </select>
              <div className="flex items-center w-full space-x-3 md:w-auto">
                <input
                  type="date"
                  id="dateDebut"
                  className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeStartDateValue}
                />
                <input
                  type="date"
                  id="dateDebut"
                  className="p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeEndDtaeValue}
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className={classNames(
                    transactions && transactions.length > 0
                      ? "text-green-700 border-green-300 hover:bg-green-50 focus:ring-green-500"
                      : "text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-gray-500 cursor-not-allowed",
                    "inline-flex items-center h-10 px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                  )}
                  onClick={() => exportToXLSData()}
                  disabled={
                    transactions && transactions.length > 0 ? false : true
                  }
                >
                  <ArrowDownTrayIcon
                    className={classNames(
                      transactions && transactions.length > 0
                        ? "text-green-500"
                        : "text-gray-500",
                      "self-center flex-shrink-0 w-5 h-5"
                    )}
                    aria-hidden="true"
                  />
                  <span className="sr-only">Exporter en XLS</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 overflow-x-auto">
          <table
            className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
            style={{ borderSpacing: 0 }}
          >
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                {tableHeader.map((th, index) => (
                  <th key={index} scope="col" className="px-4 py-3">
                    {th}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="items-center">
              {transactions && transactions.length > 0 ? (
                transactions.map((transaction: any, transactionIdx: number) => (
                  <tr
                    key={transactionIdx}
                    className={
                      transactionIdx % 2 === 0 ? "bg-white" : "bg-gray-100"
                    }
                    // onClick={() =>
                    //   onDetails(transaction.data, transaction.id)
                    // }
                  >
                    <th
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {(transactionIdx as number) + 1}
                    </th>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {user?.id === transaction.data.senderID ? (
                        <img
                          className="w-10 h-10 p-1 border rounded-full"
                          src={
                            transaction.data.receiverProfilImageUrl
                              ? transaction.data.receiverProfilImageUrl
                              : `https://ui-avatars.com/api/?background=random&name=${
                                  transaction.data.receiverDisplayName || "S"
                                }`
                          }
                          alt=""
                        />
                      ) : (
                        <img
                          className="w-10 h-10 p-1 border rounded-full lg:w-10"
                          src={
                            transaction.data.senderProfilImageUrl
                              ? transaction.data.senderProfilImageUrl
                              : `https://ui-avatars.com/api/?background=random&name=${
                                  transaction.data.senderDisplayName || "S"
                                }`
                          }
                          alt=""
                        />
                      )}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {user?.id === transaction.data.senderID
                        ? transaction.data.receiverDisplayName
                          ? transaction.data.receiverDisplayName
                          : "-"
                        : transaction.data.senderDisplayName
                        ? transaction.data.senderDisplayName
                        : "-"}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {user?.id === transaction.data.senderID
                        ? transaction.data.receiverPhoneNumber
                        : transaction.data.senderPhoneNumber}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {new Intl.NumberFormat().format(transaction.data.amount)}{" "}
                      Fcfa
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {transactionMap.get(transaction.data.transactionType)}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {transaction.data.accountType === "MAIN" ? (
                        <img
                          className="w-auto h-5"
                          src={SankMoneyIcon}
                          alt="Sank"
                        />
                      ) : (
                        <BoltIcon className="w-6 h-6 text-red-500" />
                      )}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {transaction.data.fees as string} Fcfa
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {transaction.data.isComplete ? (
                        <CheckCircleIcon className="w-6 h-6 text-green-500" />
                      ) : (
                        <XCircleIcon className="w-6 h-6 text-red-500" />
                      )}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {(transaction.data.createdAt as Timestamp)
                        .toDate()
                        .toLocaleString()}
                    </td>
                    {/* <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      <TableRowEditMenu
                        rowTransDetailsHandler={() =>
                          onDetails(transaction.data, transaction.id)
                        }
                      />
                    </td> */}
                  </tr>
                ))
              ) : (
                <NoContent />
              )}
            </tbody>
          </table>

          <Pagination
            skip={defaultLoadHistories}
            take={defaultLoadHistories}
            total={defaultLoadHistories}
            collectionName={transactionCollectionName}
            isChangedPage={true}
            onPageChange={(pageIndex: number) => pageIndex}
            onPageLimitChange={(pageLimit: number) =>
              setTransDataLimitLength(pageLimit)
            }
            balance={transactionTotalSum ? transactionTotalSum : undefined}
          />
        </div>
      </div>
    </>
  );
};

export default GasStationTransactionsHistories;
