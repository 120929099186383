export enum TransactionType {
  TRANSFER = "TRANSFER",
  RELOAD = "RELOAD",
  RELOADFUELACCOUNT = "RELOADFUELACCOUNT",
  PAYMENT = "PAYMENT",
  WITHDRAW = "WITHDRAW",
  BULKPAYMENT = "BULKPAYMENT",
  BUYAIRTIME = "BUYAIRTIME",
  ELECTRIC_BILL_POSTPAID = "ELECTRIC_BILL_POSTPAID",
  ELECTRIC_BILL_PREPAID = "ELECTRIC_BILL_PREPAID",
  AGRIVOUCHERPAYMENT = "AGRIVOUCHERPAYMENT",
  WATER_BILL_POSTPAID = "WATER_BILL_POSTPAID",
  FUNDRAISE = "FUNDRAISE",
  RELOADDEPOSITACCOUNT = "RELOADDEPOSITACCOUNT",
}

export const transactionMap = new Map<string, string>([
  ["TRANSFER", "Transfert"],
  ["RELOAD", "Recharge"],
  ["RELOADFUELACCOUNT", "Recharge carburant"],
  ["PAYMENT", "Paiement"],
  ["WITHDRAW", "Retrait"],
  ["BULKPAYMENT", "Paiement en masse"],
  ["BUYAIRTIME", "Recharge crédit d'appel ou internet"],
  ["ELECTRIC_BILL_PREPAID", "Paiement unités cashpower"],
  ["ELECTRIC_BILL_POSTPAID", "Paiement électricité"],
  ["WATER_BILL_POSTPAID", "Paiement facture ONEA"],
  ["AGRIVOUCHERPAYMENT", "Paiement intrants"],
  ["FUNDRAISE","Collecte de fonds"],
  ["RELOADDEPOSITACCOUNT","Caution"]
]);

export enum TransactionStatus {
  BLOCKED = "BLOCKED",
  CANCELLED = "CANCELLED",
  RETURNED = "RETURNED",
  VALIDETED = "VALIDETED",
}

export enum SankMoneyReloadProcessStatusenum {
  CANCELLED = "CANCELLED",
  PENDING = "PENDING",
  VALIDETED = "VALIDETED",
}

export const sankMoneyReloadProcessStatusMap = new Map<string, string>([
  ["CANCELLED", "Annuler"],
  ["PENDING", "En attente"],
  ["VALIDETED", "Validé"],
]);
